module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3978c0","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-FGW3EWK133","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"CV","name":"Telitsyn Stepan - CV","icon":"src/images/icons/icon-128.png","icons":[{"src":"src/images/icons/icon-72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/icons/icon-96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/icons/icon-128.png","sizes":"128x128","type":"image/png"},{"src":"src/images/icons/icon-144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/icons/icon-152.png","sizes":"152x152","type":"image/png"},{"src":"src/images/icons/icon-192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/images/icons/icon-384.png","sizes":"384x384","type":"image/png"},{"src":"src/images/icons/icon-512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","background_color":"#3978c0","display":"standalone","scope":"/","theme_color":"#3978c0","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d419f5ef1479e9000f48f16abb7b3561"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
